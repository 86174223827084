<template>
  <div style="position: relative; top: 0; max-width: 1200px; margin: 0 auto;">
    <div class="language-chooser" @click="locale = toggleLanguage(locale); this.$i18n.locale = locale;">
      {{ toggleLanguage(this.locale) }}
    </div>
  </div>
  <AdaptivePairContainer style="margin-top: 24px">
    <template v-slot:firstElement>
      <IntroBlock id="intro-block"/>
    </template>
    <template v-slot:secondElement>
      <div id="id-card-block">
        <IdCard id="id-card"/>
        <span class="comment" style="display: block; background: white; width: max-content; padding-bottom: 5px;">
          // {{ $t("comments.photo") }}
        </span>
      </div>
    </template>
  </AdaptivePairContainer>

  <div class="working-block">
    <h1 style="padding: 5px 0 5px 0; margin-bottom: -5px;">
      {{ $t("work.title") }}
    </h1>
    <AdaptivePairContainer>
      <template v-slot:firstElement>
        <WorkComponent :img="require('@/assets/lanit.png')" :company-name="$t('work.lanit')"  :time="$t('work.lanit_time')" :occupation="$t('work.lanit_occupation')"/>
      </template>
      <template v-slot:secondElement>
        <WorkComponent :img="require('@/assets/jetbrains.png')" :company-name="$t('work.jetbrains')"  :time="$t('work.jetbrains_time')" :occupation="$t('work.jetbrains_occupation')"/>
      </template>
    </AdaptivePairContainer>
  </div>

  <FactsComponent style="margin-top: 24px; margin-bottom: 12px;"/>
  <LinksComponent style="margin-bottom: 36px;"/>
</template>

<script>
import IdCard from "@/components/IdCard";
import AdaptivePairContainer from "@/components/AdaptivePair";
import IntroBlock from "@/components/Intro";
import WorkComponent from "@/components/WorkComponent";
import FactsComponent from "@/components/FactsComponent";
import LinksComponent from "@/components/LinksComponent";

export default {
  name: 'App',
  components: {
    LinksComponent,
    FactsComponent,
    WorkComponent,
    IntroBlock,
    AdaptivePairContainer,
    IdCard,
  },
  data() {
    return {
      locale: this.$i18n.locale,
    }
  },
  methods: {
    toggleLanguage(lang) {
      if (lang === 'en')
        return 'kz'
      else
        return 'en'
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;700&display=swap');

:root {
  --cardWidth: 473px;
  --cardHeight: 291px;
  --cardColor: #AEDBE7;
  --link-color: #0036F8;
  --comment-color: #707070;
}

body {
  background: white url("./assets/body-bg.png");
  background-size: 100vw;
}

#app {
  font-family: 'JetBrains Mono', monospace;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  font-size: 26px;
}

.link, .link:visited {
  text-decoration-line: underline;
  color: var(--link-color);
}

.comment {
  color: var(--comment-color);
}

#id-card {
  margin: 0 auto;
}

#intro-block {
  display: inline-block;
  margin-top: 24px;
  margin-bottom: 12px;
}

#id-card-block {
  display: inline-block;
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  overflow: auto;
  margin-top: 5px;
}

#id-card-block > .comment {
  margin: 0 auto;
}

.working-block {
  margin-top: 24px;
  margin-bottom: 12px;
  padding: 0 0 12px 0;
  background: white;
}

.language-chooser {
  cursor: pointer;
  display: inline-block;
  background-color: black;
  color: white;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -24px;
}

.whtt-tooltip-icon {
  /*display: none;*/
}

@media (min-width: 610px) {
  .working-block {
    padding: 0 10px 12px 10px;
  }
}

@media (min-width: 900px) {
  body {
    background-size: 60%;
  }

  #id-card-block {
    width: auto;
    overflow: unset;
    margin: 95px auto 0 auto;
  }

  #id-card-block > .comment {
    margin: 0;
  }

  #id-card {
    margin: 0;
  }

  .working-block {
    padding: 0 24px 12px 24px;
  }
}
</style>
