<template>
  <div class="links-block">
    <h1>{{ $t("links.title") }}</h1>
    <div style="display: flex; justify-content: space-between; margin: 0 auto; align-items: center; max-width: 500px;">
      <a href="https://twitter.com/lippfi1">
        <img src="@/assets/twitter-logo.svg" alt="twitter logo" style="width: 80px;">
      </a>
      <a href="https://github.com/lippfi/">
        <img src="@/assets/github-logo.svg" alt="github logo" style="width: 80px;">
      </a>
      <a href="https://t.me/lippfi">
        <img src="@/assets/telegram.svg" alt="discord logo" style="width: 80px;">
      </a>
      <a href="https://lipp.fi/blog">
        <img src="@/assets/blog.svg" alt="my blog" style="width: 80px;">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "LinksComponent"
}
</script>

<style scoped>
.links-block {
  margin: 24px auto 12px auto;
  padding: 1px 0 12px 0;
  background: white;
}

@media (min-width: 900px) {
  .links-block {
    padding: 1px 24px 12px 24px;
  }
}
</style>