<template>
  <div class="facts-container">
    <h1>{{ $t("facts.title") }}</h1>
    <p>
      {{ $t("facts.hobbies") }} <span class="comment"> // {{ $t("comments.blog") }}</span>
    </p>
    <p>{{ $t("facts.snakes") }}</p>
    <p>{{ $t("facts.books") }}</p>
    <p>{{ $t("facts.stoicism") }}</p>
    <p>{{ $t("facts.pet_projects") }}<span class="comment"> // {{ $t("comments.pet_blog") }}</span></p>
    <p>{{ $t("facts.vimI3_before_link") }}<a href="https://github.com/lippfi/dotfiles/" class="link">{{ $t("facts.vimI3_link") }}</a>{{ $t("facts.vimI3_after_link") }}</p>
    <p style="margin-bottom: 48px;">{{ $t("facts.kazakh_version") }}</p>

    <h1>{{ $t("epilogue.title") }}</h1>
    <p style="margin-bottom: 0;">{{ $t("epilogue.p1") }}</p>
    <img src="../assets/snowflake.svg" alt="Snowflake guy" style="display: block; max-width: 400px; width: 90%; margin: 25px auto;">
    <p style="margin-top: 0;">{{ $t("epilogue.p2") }}</p>
  </div>
</template>

<script>
export default {
  name: "FactsComponent",
}
</script>

<style scoped>
.facts-container {
  padding: 1px 0 12px 0;
  background: white;
}

@media (min-width: 610px) {
  .facts-container {
    padding: 1px 10px 12px 10px;
  }
}

@media (min-width: 900px) {
  .facts-container {
    padding: 1px 24px 12px 24px;
  }
}
</style>