import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from "vue-i18n";

const messages = {
    en: {
        intro: {
            title: "Cool and Awesome Title",
            greeting: "Hi there! Welcome to my webpage.",
            about_me: "My name is Filipp and I am a software developer from Kazakhstan, currently living in Cyprus.",
            about_page: {
                before_link: "I’ve bought this domain to sign my Java packages. According to ",
                link: "Java naming conventions",
                after_link: " reversed domain names are used to begin package names, and with this domain I can use the reversed subdomain fi.lipp.vakhitov which is my first and last name. It’s kinda cool, isn’t it?",
            },
        },
        work: {
            title: "Work Experience",
            lanit: "Lanit",
            lanit_time: "2020 - 2021",
            lanit_occupation: "Backend Java developer",
            jetbrains: "JetBrains",
            jetbrains_time: "2021 - present",
            jetbrains_occupation: "Java/Kotlin developer",
        },
        comments: {
            photo: "One day I'll make a better photo, I promise",
            blog: "I will post some photos as I finish blog",
            pet_blog: "The blog is one of the projects",
        },
        facts: {
            title: "Some Facts About Me",
            hobbies: "My hobbies are cooking, origami and knife throwing.",
            snakes: "I'm a snake keeper and a latent spider lover.",
            books: "I used to be a host of a book club and would like to revive it one day. At the moment I read books with different people and discuss them in discord, feel free to contact me.",
            stoicism: "I'm interested in Stoicism.",
            pet_projects: "I have lots of pet projects and one day they will be available on this page.",
            vimI3_before_link: "I love Vim and i3. There is nothing special in my setup, but you can see my dotfiles ",
            vimI3_link: "here",
            vimI3_after_link: ".",
            kazakh_version: "I don't speak Kazakh, but this page has a Kazakh version.",
        },
        epilogue: {
            title: "Epilogue",
            p1: "Russian have an idiom to call an all thumbs person. The say \"this guy's hands are growing from his ass\". Maxim Dorofeev on his training about team management demonstrates the following picture and says that a team full of good developers can have the \"ass-hands-snowflake-guy\" in it.",
            p2: "But.. look at this picture. The ass-hands-snowflake-guy is the only one smiling there.",
        },
        links: {
            title: "Links",
        },
    },
    kz: {
        intro: {
            // title: "Әсерлі әрі керемет тақырып",
            title: "Әсерлі тақырып",
            greeting: "Сәлем! Менің парақшама қош келдіңіз!",
            about_me: "Менің атым Филипп және қазіргі таңда Кипрде тұратын қазақстандықпын, бағдарламалық қамтамасыз етуді әзірлеушімін.",
            about_page: {
                before_link: "Мен бұл доменді өзімнің Java пакеттерімді жазу үшін сатып алдым. ",
                link: "Java аттары конвенциясына сәйкес",
                after_link: " пакеттер керісінше домендік аттармен жазылады, ал мен осы доменмен менің атым және тегімді fi.lipp.vakhitov керісінше домендік атты қолдана аламын. Керемет, иә?",
            },
        },
        work: {
            title: "Жұмыс өтілі ",
            lanit: "Ланит",
            lanit_time: "2020 - 2021",
            lanit_occupation: "Бэкенд Java әзірлеушісі",
            jetbrains: "JetBrains",
            jetbrains_time: "2020 - қазіргі сәт",
            jetbrains_occupation: "Java/Kotlin әзірлеушісі",
        },
        comments: {
            photo: "Бір күні мен жақсы сурет қоямын, уәде беремін",
            blog: "Блогты аяқтағаннан кейін фотосуреттер пайда болады.",
            pet_blog: "Блог - осы жобалардың бірі",
        },
        facts: {
            title: "Қысқаша мен жайлы",
            hobbies: "Мен тамақ пісіруді, оригамиді және пышақ лақтыруды ұнатамын.",
            snakes: "Жыланды асырайнмын. Өрмекшілерді жақсы көремін.",
            books: "Кітап клубының ұйымдастырушысымын және оны бір күні жандандырғым келеді. Қазір мен әр түрлі адамдармен кітап оқып, оларды дискордта талқылаймын, бұл туралы маған жазғыңыз келсе, тартынбаңыз.",
            stoicism: "Мен стоицизмге қызығамын.",
            pet_projects: "Менде көптеген үй жобалары бар және сіз оларды осы веб-бетте көре аласыз.",
            vimI3_before_link: "Мен Vim және i3 жақсы көремін. Менің конфигурациямда ерекше ештеңе жоқ, бірақ сіз оны ",
            vimI3_link: "осы",
            vimI3_after_link: " жерден таба аласыз.",
            kazakh_version: "Мен қазақша сөйлемеймін, бірақ бұл беттің қазақша нұсқасы бар.",
        },
        epilogue: {
            title: "Эпилог",
            p1: "Орыстарда қолайсыз жаағдайларда қолданатын мәтелдері бар. Олар мұндай адамдарда қолдары көттерінен өседі дейді. Максим Дорофеев өзінің команданы басқару бойынша тренингінде осы суретті көрсетеді және тіпті мықты әзірлеушілердің тобында да «қолы көтінен көрінетін жандардың» бар екенін айтады.",
            p2: "Бірақ.. бұл суретке мұқият қараңызшы. Қолы көтінен көрініп тұрған жан ғана күліп тұр.",
        },
        links: {
            title: "Сілтемелер",
        },
    }
}

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
})

const app = createApp(App)

app.use(i18n)
app.mount('#app')

