<template>
  <div class="flex-responsive-container">
    <slot name="firstElement"></slot>
    <slot name="secondElement"></slot>
  </div>
</template>

<script>
export default {
  name: "AdaptivePairContainer",
}
</script>

<style scoped>
.flex-responsive-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 900px) {
  .flex-responsive-container {
    flex-direction: row;
  }
}

</style>