<template>
  <div class="id-card">
    <div style="padding: 25px">

      <div class="id-card-header">
        <span>IDENTITY CARD</span>
        <span>ЖЕКЕ КУӘЛІК</span>
      </div>

      <div class="id-card-content">
        <img src="@/assets/id_photo.png" class="id-card-photo" alt="My photo">
        <div class="id-card-fields">
          <div class="id-card-field">
            <span class="id-card-field-key">Name / Аты</span>
            <span class="id-card-field-value">Filipp</span>
          </div>
          <div class="id-card-field">
            <span class="id-card-field-key">Date of birth / Туған күнi</span>
            <span class="id-card-field-value">30.03.1999</span>
          </div>
          <div class="id-card-field">
            <span class="id-card-field-key">Special moves / Cоққтар</span>
            <span class="id-card-field-value">
                                <a href="https://www.wowhead.com/spell=246043/asleep" class="link">Asleep</a>,
                                <a href="https://www.wowhead.com/wotlk/spell=50267/demoralizing-mmmrrrggglll" class="link">Demoralizing Mmrrggl</a>
                            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "IdCard",
  mounted() {
    let wowHeadScript = document.createElement('script')
    wowHeadScript.setAttribute('src', 'https://wow.zamimg.com/widgets/power.js')
    document.head.appendChild(wowHeadScript)
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@700&display=swap');

.id-card {
  width: var(--cardWidth);
  height: var(--cardHeight);
  background: var(--cardColor);
  background-image: url("@/assets/card-bg.png");
  background-size: var(--cardWidth) var(--cardHeight);
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 14px;
}

.id-card-header {
  display: flex;
  justify-content: space-between;
  margin-right: 26px;
}

.id-card-header > span {
  font-family: 'IBM Plex Serif',serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.id-card-content {
  display: flex;
}

.id-card-photo {
  width: 135px;
  height: 180px;
  margin-top: 20px;
}

.id-card-fields {
  margin-top: 23px;
}

.id-card-field {
  margin-left: 15px;
  margin-bottom: 20px;
}

.id-card-field-key {
  display: block;
  font-size: 16px;
  font-weight: 500;
}

.id-card-field-value {
  display: block;
  font-size: 16px;
}
</style>