<template>
  <div class="intro-block">
    <h1>{{ $t("intro.title") }}</h1>
    <p>{{ $t("intro.greeting") }}</p>
    <p>{{ $t("intro.about_me") }}</p>
    <p>
      {{ $t("intro.about_page.before_link") }}
      <a href="https://docs.oracle.com/javase/tutorial/java/package/namingpkgs.html" class="link">
        {{ $t("intro.about_page.link") }}
      </a>
      {{ $t("intro.about_page.after_link") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "IntroBlock"
}
</script>

<style scoped>
.intro-block {
  padding: 0 0 12px 0;
  background: white;
  margin: 0 auto;
}

@media (min-width: 610px) {
  .intro-block {
    padding: 0 10px 12px 10px;
  }
}

@media (min-width: 900px) {
  .intro-block {
    max-width: 590px;
    padding: 0 24px 12px 24px;
    margin: 5px 0 0;
  }
}
</style>