<template>
  <div class="workplace">
    <div class="logo">
      <img v-bind:src="img" :alt="companyName + ' logo'">
    </div>
    <div class="text">
      <p>{{ companyName }} {{ time }}</p>
      <p>{{ occupation }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkComponent",
  props: {
    img: String,
    companyName: String,
    time: String,
    occupation: String,
  }
}
</script>

<style scoped>
.logo {
  float: left;
  width: 100px;
  height: 100px;
  position: relative;
  margin-right: 20px;
}

.logo > img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.workplace {
  padding: 10px 0 10px 0;
  margin: 20px auto;
  width: 100%;
}

.text {
  margin-top: -22px;
}

@media (min-width: 900px) {
  .workplace {
    display: inline-block;
    margin: 20px 0;
    max-width: 500px;
  }

  .logo {
    width: 125px;
    height: 125px;
  }

  .text {
    margin-top: 30px;
  }
}
</style>